export const Config = {
  Auth: {
    region: "ap-southeast-2",
    userPoolId: "ap-southeast-2_DUuiMr8c8",
    userPoolWebClientId: "6eg6rpaheo2630ffaljt9pg1iq",
    oauth: {
      domain: "staff-identity.club-journey.com.au",
      redirectSignIn: "https://portal.club-journey.com.au/auth/oauth",
      redirectSignOut: "https://portal.club-journey.com.au/signin",
      scope: ["email", "openid", "phone", "profile", "aws.cognito.signin.user.admin"],
      responseType: "code",
    },
  },
  AppSync: {
    Staff: {
      graphqlEndpoint:  "https://api.staff.club-journey.com.au/graphql",
      region: "ap-southeast-2",
    },
    Public: {
      graphqlEndpoint:  "https://api.public.club-journey.com.au/graphql",
      region: "ap-southeast-2",
    },
 }
};
export default Config;
